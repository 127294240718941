import React from "react";

const GrindingBallsBanner = () => {
  return (
    <div>
      <div className="relative w-full h-full">
        <img
          src={"/assets/products/Balls.svg"}
          alt="background"
          className="w-full h-full object-cover relative z-[-10]"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
        <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
      </div>


      <div className="flex flex-col justify-start item-start space-y-2 sm:space-y-4 md:space-y-6 absolute md:w-[78%] lg:w-[70%]  top-[20%] sm:top-[30%] md:top-[30%] lg:[top-50%] left-[8%] md:[left-9%]">
      <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl">
              Grinding Balls
          </h1>
          <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block">Products / Mining / Grinding Balls</p>

        <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
        Our grinding balls deliver high abrasion-resistant grinding with a martensitic base and ultra-hard chrome carbides for strength and durability. Tailored to your needs, they ensure peak efficiency and minimal wear, reducing consumption, breakage, and mill downtime for increased productivity.
        </p>

      </div>


    </div>
  );
};

export default GrindingBallsBanner;