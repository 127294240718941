import React, { useState, useRef } from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";
import { Map, Marker } from "pigeon-maps";

export default (props: templates.BallLoading) => {
  const scrollContainerRef = useRef(null);

  const [center, setCenter] = useState<[number, number]>([12.9716, 77.5946]);

  const locations: { name: string; coordinates: [number, number] }[] = [
    { name: "Ras Al Khaima (UAE)", coordinates: [25.8007, 55.9762] },
    { name: "Tatanagar (Jharkhand)", coordinates: [22.8046, 86.2029] },
    { name: "Hyderabad (Telangana)", coordinates: [17.385, 78.4867] },
    { name: "Bellary (Karnataka)", coordinates: [15.1394, 76.9214] },
    { name: "Bengaluru (Karnataka)", coordinates: [12.9716, 77.5946] },
    { name: "Piara Waters", coordinates: [-32.1371, 115.9181] },
    { name: "Perth, Australia", coordinates: [-31.9505, 115.8605] },
  ];

  return (
    <Scaffold title="About Us">
      <Navbar scrollContainerRef={scrollContainerRef} />

      <div
        ref={scrollContainerRef}
        className="h-screen overflow-y-scroll sm:snap-y sm:snap-mandatory pt-0 sm:pt-20 scroll-smooth"
      >
        <section className="snap-start">
          <div className="relative">
            <div className="hidden sm:block">
              <div className="relative w-full h-screen">
                <img
                  src={"/assets/products/AboutUs.svg"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              <div className="flex flex-col justify-start item-start space-y-2 sm:space-y-4 md:space-y-6 absolute md:w-[78%] lg:w-[70%]  top-[20%] sm:top-[30%] md:top-[50%] lg:[top-50%] left-[8%] md:[left-9%]">
                <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl">
                  About Us
                </h1>
                <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                  Scripting the future of industrial manufacturing
                </p>
              </div>
            </div>

            <div className="block sm:hidden">
              <div className="relative w-full h-full">
                <img
                  src={"/assets/products/AboutUsMob.svg"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              <div className="absolute inset-0 flex flex-col justify-center items-center text-white mx-10">
                <h1 className="font-heading font-normal text-2xl">About Us</h1>
                <p className="font-body text-sm mt-5 text-center">
                  Scripting the future of industrial manufacturing
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="snap-start">
          <div className="mx-12 sm:mx-10 md:mx-16 lg:px-32 pb-10 pt-0 sm:pt-24 font-body text-sm sm:text-lg text-justify leading-5 sm:leading-8">
            <p className="my-4">
              Hiperoy Solutions is a leader in business solutions, committed to
              helping industry leaders and enterprises achieve their goals
              through innovative, tailored strategies. By combining cutting-edge
              technology with deep industry expertise in the fields of
              logistics, production and post-sales we deliver customized
              solutions designed to meet your unique challenges and drive
              sustainable growth.
            </p>

            <p className="my-4">
              We focus on long-term success, going beyond quick fixes. With over
              60 years of combined technical experience, we have shaped
              processes that excel in manufacturing and after-sales service. Our
              multiple manufacturing units across India, with strategic
              personnel, ensure reliable supply and support, backed by
              international exposure through worldwide agents for sales and
              services.
            </p>

            <p className="my-4">
              At Hiperoy, we're problem solvers at heart. We strive to be the
              company you trust when challenges arise, dedicated to keeping your
              business efficient, reliable, and always ahead of the competition.
            </p>

            <div className="container ">
              <h1 className="my-6 sm:my-16 text-2xl font-bold font-heading text-start sm:text-center">
                Our Locations
              </h1>

              <div className="relative h-72 sm:h-96 md:h-128 lg:h-[560px] w-full sm:w-4/5 rounded-lg overflow-hidden mx-auto">
                <Map center={center} defaultZoom={3}>
                  {locations.map((location, index) => (
                    <Marker
                      key={index}
                      width={40}
                      anchor={location.coordinates}
                      color="Red"
                    />
                  ))}
                </Map>
              </div>
            </div>
          </div>
          <ContactInfo />
          <Footer />
        </section>
      </div>
    </Scaffold>
  );
};
