import React from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import MillLinersBanner from "../components/MillLinersBanner";

import ContactInfo from "../components/ContactInfo";



export default (props: templates.CementMillLiners) => {
    return (
        <Scaffold title="Cement Mill Liner">
            <Navbar/>

            <div>
                <div className="relative w-full h-full">
                    <img
                    src={"/assets/products/CMillLiners.svg"}
                    alt="background"
                    className="w-full h-full object-cover relative z-[-10]"
                    />
                    <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                    <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
                </div>


                <div className="flex flex-col justify-start item-start space-y-2 sm:space-y-4 md:space-y-6 absolute md:w-[78%] lg:w-[70%]  top-[20%] sm:top-[30%] md:top-[30%] lg:[top-50%] left-[8%] md:[left-9%]">
                <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl">
                    Mill Liners
                    </h1>
                    <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block">Products / Mining / Mill Liners</p>

                    <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                    We offer premium metallic liners for SAG, AG, ball, and rod mills used in mineral processing. Expertly crafted to fit each mill’s needs, our liners ensure peak performance and durability. Tailored to tackle specific challenges, they boost efficiency, reduce wear, and extend service life, making your grinding operations more effective and cost-efficient.
                    </p>

                </div>
            </div>

            <div className="mx-8 sm:mx-10 md:mx-16  lg:px-32 my-10 sm:my-20">

            <div className="flex flex-col">
                <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">Key Features</h1>
                <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                    <li className="list-disc">Custom Design: Tailored to client specifications and mill requirements to ensure optimal fit and performance.</li>

                    <li className="list-disc">Durability: Made from high-quality metals and alloys, providing enhanced resistance to wear and impact.</li>

                    <li className="list-disc">Enhanced Performance: Designed to improve mill efficiency and grinding performance through advanced profile engineering.</li>

                    <li className="list-disc">Collaborative Design: Developed in collaboration with clients to address specific operational needs and enhance liner effectiveness.</li>

                    <li className="list-disc">Extended Service Life: Engineered to withstand the harsh conditions of mineral grinding, resulting in longer-lasting performance and reduced downtime.</li>

                    <li className="list-disc"> Efficiency Improvement: Helps in reducing energy consumption and improving throughput by optimizing the grinding process.</li>

                </ul>
            </div>
            <ContactInfo/>
            </div>
            <Footer/>
        </Scaffold>
    )
}