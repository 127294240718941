import React from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";


export default (props: templates.BallLoading) => {
    return (
        <Scaffold title="Ball Loading">
            <Navbar/>

            <div>
                <div className="relative w-full h-full">
                    <img
                    src={"/assets/products/BallLoading.svg"}
                    alt="background"
                    className="w-full h-full object-cover relative z-[-10]"
                    />
                    <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                    <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
                </div>


                <div className="flex flex-col justify-start item-start space-y-2 sm:space-y-4 md:space-y-6 absolute md:w-[78%] lg:w-[70%]  top-[20%] sm:top-[30%] md:top-[30%] lg:[top-50%] left-[8%] md:[left-9%]">
                    <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl">
                    Ball Loading
                        </h1>
                        <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block">Products / Mining / Ball Loading</p>

                    <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                    A ball loading machine is a mobile device designed to efficiently load media balls into ball mill grinders, ensuring even distribution and improving milling efficiency. It enhances overall performance by reducing manual effort and ensuring consistent media placement. By streamlining the process, it optimizes production and keeps operations running smoothly.
                    </p>

                </div>

            </div>

            <div className="mx-8 sm:mx-10 md:mx-16 lg:px-32 my-10 sm:my-20">

                <div className="flex flex-col">
                    <h1 className="mb-6 sm:mb-10 text-2xl  font-bold font-heading text-start sm:text-center">Features</h1>
                    <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                        <li className="list-disc">Cleated Conveyor: Equipped with cleats at regular intervals, designed for ball sizes up to 60 mm and a feed rate of 20 TPH.</li>
                        <li className="list-disc">Mobile Design: Features a portable, mobile-style design with a turntable, tow bar, and solid rubber tires for easy relocation.</li>
                        <li className="list-disc">Durable Belting: Utilizes high-impact resistant rubber belting with steel reinforcement, capable of withstanding temperatures up to 100°C and featuring an emery-grade cover with sidewalls and cleats.</li>
                        <li className="list-disc">Control Panel: Includes a vibro-feeder control panel with a plug-and-socket connection on a skid-mounted hopper, with an option for connection to a mill building power outlet.</li>
                        <li className="list-disc">Cable Reeling Drum: An option for moving the conveyor includes a cable reeling drum for efficient mobility.</li>
                        <li className="list-disc">Electro-Magnetic Feeder: Features a detachable hopper for ball delivery and an electro-magnetic feeder that supports variable feeding rates.</li>
                        <li className="list-disc">Productivity Enhancement: Designed to improve mill productivity by optimizing the movement and sorting of grinding media balls.</li>
                        <li className="list-disc">Loading Capacity: Handles a capacity of 20 TPH with ball sizes ranging from 15 to 110 mm.</li>


                    </ul>
                </div>

                    <div className="flex justify-center w-full h-full">
                        <img
                            src={"/assets/products/BallLoadingContent.svg"}
                            alt="background"
                            className="w-1/2 h-1/2 object-cover"
                        />
                    </div>
                </div>


            <ContactInfo/>
            <Footer/>
        </Scaffold>
    )
}