import React, { useRef } from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";
import ExpandableContent from "../components/ExpandableContent";

export default (props: templates.Spares) => {
  const scrollContainerRef = useRef(null);

  return (
    <Scaffold title="Parts">
      <Navbar scrollContainerRef={scrollContainerRef} />

      <div
        ref={scrollContainerRef}
        className="h-screen overflow-y-scroll sm:snap-y sm:snap-mandatory pt-0 sm:pt-20 scroll-smooth"
      >
        <section className="snap-start">
          <div className="relative">
            <div className="hidden sm:block">
              <div className="relative w-full h-screen">
                <img
                  src={"/assets/products/Spares.svg"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              <div className="flex flex-col justify-start item-start absolute top-[16%] sm:top-[40%] left-[4%] sm:left-[10%] w-[80%]">
                <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl mb-0 sm:mb-5">
                  Parts
                </h1>
                <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block mb-0 sm:mb-10">
                  Products / Parts
                </p>

                <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                  We offer a range of high-quality spares to keep your mining
                  and cement equipment running at peak performance. Our parts,
                  including hammers, jaws, and cones, are built for durability
                  and dependability, minimizing downtime and ensuring smooth,
                  efficient operations.
                </p>
              </div>
            </div>

            <div className="block sm:hidden">
              <div className="relative w-full h-full">
                <img
                  src={"/assets/products/PartsMob.svg"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              <div className="absolute inset-0 flex flex-col justify-center items-center text-white mx-10">
                <h1 className="font-heading font-normal text-2xl">Parts</h1>
                <p className="font-body text-sm mt-5 text-center">
                  Our spares ensure reliable performance and longevity for
                  mining and cement equipment, minimizing downtime and
                  maintaining smooth operations.{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="snap-start">
          <div className="mx-12 sm:mx-10 md:mx-16 lg:px-32 pt-0 sm:pt-20">
            <div className="flex flex-col justify-center items-center">
              <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading">
                Key Features
              </h1>
              <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg text-justify leading-5 sm:leading-8">
                <li className="list-disc">
                  Exact Precision: Each component is crafted to precise
                  specifications, ensuring a perfect fit and top-notch
                  functionality.
                </li>
                <li className="list-disc">
                  Dependable Performance: Our parts enhance equipment efficiency
                  and reliability, reducing operational disruptions.
                </li>
                <li className="list-disc">
                  Premium Materials: Made from high-grade materials, our spares
                  resist wear and meet strict quality standards.
                </li>
              </ul>
            </div>

            <div className="flex flex-col justify-center items-center font-body md:px-20 lg:px-24 xl:px-64">
              <ExpandableContent
                title="Hammer"
                content="HIPEROY’s hammer spares are crafted for durability and precision, guaranteeing efficient crushing and reduced wear in mining and cement processing. Built to endure heavy impacts, these hammers boost equipment performance and prolong service life."
                moreInfo={false}
              />
              <ExpandableContent
                title="Jaw"
                content="Our jaw spares are built for exceptional durability and reliability. They ensure efficient crushing in tough mining and cement environments. Designed to boost equipment performance, reduce downtime, and extend the lifespan of your jaw crushers, ensuring smooth operations."
                moreInfo={false}
              />
              <ExpandableContent
                title="Cone"
                content="HIPEROY’s cone spares deliver unmatched durability and precision. They ensure steady, efficient crushing in mining and cement operations. Designed to maintain peak performance, these spares also extend the lifespan of your cone crushers, keeping your operations smooth and productive."
                moreInfo={false}
              />
            </div>
          </div>

          <ContactInfo />
          <Footer />
        </section>
      </div>
    </Scaffold>
  );
};
