import React from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";
import ExpandableContent from "../components/ExpandableContent";


export default (props: templates.MobileSeries) => {
    return (
        <Scaffold title="Mobile Series">
            <Navbar/>

            <div>
                <div className="relative w-full h-full">
                    <img
                    src={"/assets/products/MobileSeries.svg"}
                    alt="background"
                    className="w-full h-full object-cover relative z-[-10]"
                    />
                    <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                    <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
                </div>


                <div className="flex flex-col justify-start item-start space-y-2 sm:space-y-4 md:space-y-6 absolute md:w-[78%] lg:w-[70%]  top-[20%] sm:top-[30%] md:top-[30%] lg:[top-50%] left-[8%] md:[left-9%]">
                    <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl">
                    Mobile Series
                    </h1>
                    <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block">Products / Mining / Equipment / Mobile Series</p>

                    <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                    HIPEROY’s Mobile Series equipment delivers unmatched flexibility and efficiency. Our mobile crushers and screens, including track-mounted units, are built to fit any mining site. They offer quick setup and top performance, handling tough conditions with ease. Designed for on-the-go operations, these units ensure high productivity and reliable performance wherever you need it.
                    </p>

                </div>
            </div>

            <div className="mx-9 sm:mx-10 md:mx-16 lg:px-32 my-10 sm:my-20">

                <div className="flex flex-col">
                    <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">Key Features</h1>
                    <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                        <li className="list-disc">Mobility: Track-mounted units provide easy relocation and quick setup across various mining sites .</li>
                        <li className="list-disc">Flexibility: Adaptable to different terrains and operational requirements, enhancing versatility in the field .</li>
                        <li className="list-disc">High Productivity: Engineered for efficient on-site crushing and screening, ensuring consistent output.</li>
                        <li className="list-disc">Durable Design: Built to withstand harsh conditions while maintaining reliable performance.</li>
                        <li className="list-disc">Compact Configuration: Designed for space efficiency without compromising on power or capability .</li>
                        <li className="list-disc">Rapid Deployment: Allows for fast transitions between sites, minimizing downtime and maximizing operational efficiency.</li>
                    </ul>
                </div>

            <div className="flex flex-col justify-center items-center font-body md:px-20 lg:px-24 xl:px-64">

                <ExpandableContent
                    title="Track Mounted Jaw"
                    content="HIPEROY’s Track-Mounted Jaw is a versatile, mobile unit engineered for efficient, on-site operations. It offers excellent mobility, allowing for quick setup and relocation across various mining environments. Designed for high performance, this unit enhances flexibility and productivity, ensuring reliable operation and efficient material handling in dynamic and challenging conditions."
                    moreInfo={false}
                />
                <ExpandableContent
                    title="Track Mounted Cone"
                    content="HIPEROY’s Track-Mounted Cone offers efficient secondary and tertiary crushing with excellent mobility. Its track-mounted design allows for quick setup and relocation, providing reliable performance and enhanced productivity in various mining environments."
                    moreInfo={false}
                />
                <ExpandableContent
                    title="Track Mounted Endurer"
                    content="HIPEROY’s Track-Mounted Endurer is a versatile mobile unit designed for reliable, high-performance screening. Its track-mounted design ensures excellent mobility and quick setup, making it ideal for dynamic mining environments. Engineered for durability and efficiency, it enhances productivity and provides consistent, high-quality screening results."
                    moreInfo={false}
                />
                <ExpandableContent
                    title="Track Mounted Vertical Shaft Impactor"
                    content="HIPEROY’s Track-Mounted Vertical Shaft Impactor is a high-performance mobile unit designed for efficient and versatile impact crushing. Its track-mounted design provides exceptional mobility and quick relocation, making it ideal for dynamic mining operations. Engineered for durability and precision, it delivers reliable, high-quality impact crushing and enhances overall productivity on various job sites."
                    moreInfo={false}
                />
                <ExpandableContent
                    title="Track Mounted Screen"
                    content="HIPEROY’s Track-Mounted Screen is a mobile unit designed for efficient and flexible screening in mining operations. Its track-mounted design ensures easy mobility and rapid setup, allowing for effective separation and classification of materials on-site. Engineered for durability and high performance, it enhances productivity and provides reliable, consistent screening results across diverse mining environments."
                    moreInfo={false}
                />
                <ExpandableContent
                    title="Track Mounted Scalper"
                    content="HIPEROY’s Track-Mounted Scalper is a robust mobile unit designed for effective primary screening and material separation. Its track-mounted design provides excellent mobility and quick setup, allowing for efficient processing of large volumes of material. Engineered for durability and high performance, it ensures reliable operation and enhances productivity in dynamic mining environments."
                    moreInfo={false}
                />


            </div>
            
            </div>


            <ContactInfo/>
            <Footer/>
        </Scaffold>
    )
}