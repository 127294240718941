import React from "react";

const BodyLinerBanner = () => {
  return (
    <div>
      <div className="relative w-full h-full">
        <img
          src={"/assets/products/BodyLiner.svg"}
          alt="background"
          className="w-full h-full object-cover relative z-[-10]"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
        <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
      </div>


      <div className="flex flex-col justify-start item-start space-y-2 sm:space-y-4 md:space-y-6 absolute md:w-[78%] lg:w-[70%]  top-[20%] sm:top-[30%] md:top-[30%] lg:[top-50%] left-[8%] md:[left-9%]">
      <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl">
        Body Liners
          </h1>
          <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block">Products / Mining / Body Liners</p>

        <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
        Our body liners are engineered with carefully selected alloys to effectively reduce wear
         caused by the air flow through the mill and the abrasive materials. 
        The advanced design and high-quality materials used in our liners ensure outstanding resistance to erosion and abrasion. 
        This results in a longer lifespan, consistent performance, and decreased maintenance requirements, 
        ultimately improving efficiency and reducing overall operating costs.
          </p>

      </div>


    </div>
  );
};

export default BodyLinerBanner;
