import React from "react";
import { templates } from "@reactivated";
import { Scaffold } from "../components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ProductBanner from "../components/ProductBanner";
import ContactInfo from "@client/components/ContactInfo";
import ExpandableContent from "@client/components/ExpandableContent";


export default (props: templates.Product) => {


    const products = [
        { text: "Grinding Media", href: "mining/grinding-balls/" },
        { text: "Mill Liners", href: "#mill-liners" },
        { text: "Body Liners", href: "#body-liners" },
        { text: "Ball Loading", href: "#ball-loading" },
        { text: "Ball Sorting", href: "#ball-sorting" },
        { text: "Equipment", href: "#equipment" },
        { text: "Spares", href: "#spares" }
    ]

    return (
        <Scaffold title="Product">
            <Navbar/>
            <ProductBanner/>

            <div className="mx-8 sm:mx-10 md:mx-16 lg:px-32 my-10 sm:my-20">

                <div className="flex flex-col">
                    <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">Mining</h1>
                        <div className="font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                            <p>
                            HIPEROY is your trusted partner in the mining industry, offering a diverse portfolio of high-performance products designed to 
                            meet the rigorous demands of modern mining operations. 
                            Our grinding media, including cast alloy, high chrome, and hyper steel grinding balls, are engineered for maximum durability and efficiency, 
                            ensuring optimal performance in grinding applications. 
                            We also provide robust mill liners and body liners that enhance the longevity and productivity of your milling equipment. 
                            Our innovative ball loading and ball sorting systems streamline operations, further boosting efficiency and reducing downtime.
                            </p>
                            <p className="mb-6 sm:mb-20 my-3">In addition to our advanced grinding solutions, HIPEROY offers a wide range of equipment tailored for mining operations. 
                            Our crushers, including jaw and cone crushers, are designed for high-capacity, reliable crushing performance. 
                            Our mobile series features track-mounted options like jaw crushers, cone crushers, vertical shaft impactors, screens, and scalpers, 
                            providing versatile, on-the-go solutions for any mining site. The semi-mobile Profast series delivers flexibility and efficiency, 
                            with jaw, cone, and vertical shaft impactor options to suit various operational needs. To keep your equipment running at peak performance, 
                            HIPEROY also offers precision-engineered spares, including hammers, jaws, and cones, ensuring reliable and long-lasting operation.
                        </p>
                        </div>
                </div>


                <div className="flex flex-col justify-center items-center font-body md:px-20 lg:px-24 xl:px-64">

                    <ExpandableContent
                        title="Grinding Media"
                        content="HIPEROY’s grinding media, including high chrome and hyper steel grinding balls, 
                        are designed for optimal durability and performance, ensuring efficient and precise grinding in both mining and 
                        cement manufacturing. Our media solutions help enhance milling efficiency, 
                        delivering consistent quality and extended equipment life."
                        moreInfo={true}
                        href="grinding-balls/"
                    />

                    <ExpandableContent
                    title="Mill Liners"
                    content="HIPEROY’s Mill Liners are engineered to provide superior protection and durability in the harshest mining environments. 
                    Designed to withstand extreme wear and impact, our liners enhance the performance and lifespan of your grinding mills. 
                    By optimizing the liner design, HIPEROY ensures efficient ore processing, reduced maintenance costs, and improved overall productivity in mining operations.More Info"
                    moreInfo={true}
                    href="mill-liners/"
                    />

                    <ExpandableContent
                    title="Body Liners"
                    content="HIPEROY’s Body Liners are specifically designed to protect your mining equipment from the intense wear and tear of daily operations. 
                    Crafted from high-strength, wear-resistant materials, these liners ensure the longevity and reliability of your machinery. 
                    By reducing the impact and abrasion on critical components, HIPEROY’s Body
                    Liners help minimize downtime and maintenance costs, keeping your mining operations running smoothly and efficiently."
                    moreInfo={true}
                    href="body-liners/"
                    />

                    <ExpandableContent
                    title="Ball Loading"
                    content="HIPEROY’s Ball Loading services ensure precise and efficient loading of grinding media into your milling equipment. 
                    Our expert techniques and equipment are designed to optimize the ball charge, enhancing grinding efficiency and performance. 
                    By ensuring accurate and timely ball loading, 
                    HIPEROY helps maximize the productivity of your mining operations and extend the lifespan of your milling equipment."
                    moreInfo={true}
                    href="ball-loading/"
                    />


                    <ExpandableContent
                    title="Ball Sorting"
                    content="HIPEROY’s Ball Sorting services are designed to optimize the performance of your grinding mills by accurately 
                    categorizing and separating grinding media based on size and condition. Our advanced sorting techniques ensure that worn 
                    or damaged balls are removed, while properly sized and functional balls are reintroduced into the milling process. 
                    This service enhances grinding efficiency, reduces wear on equipment, and helps maintain consistent milling performance, 
                    contributing to the overall productivity of your mining operations."
                    moreInfo={true}
                    href="ball-sorting/"
                    />

                    <ExpandableContent
                    title="Equipment"
                    content="HIPEROY offers a diverse range of high-performance mining equipment, including robust jaw and cone crushers, 
                    as well as versatile mobile and semi-mobile solutions. Our equipment is 
                    engineered for durability and efficiency, ensuring reliable performance and enhanced productivity in challenging mining environments."
                    moreInfo={true}
                    href="mining-equipment/"
                    />
                    
                    <ExpandableContent
                    title="Spares"
                    content="HIPEROY offers a comprehensive range of high-quality spare parts designed to maintain the efficiency and 
                    longevity of our mining and cement equipment.
                     Our spares ensure optimal performance and reduce downtime, keeping your operations running smoothly."
                    moreInfo={true}
                    href="spares/"
                    />

                </div>

                <div className="flex flex-col">
                    <h1 className="my-6 sm:my-14 text-2xl text- font-bold font-heading text-start sm:text-center">Cement</h1>
                        <div className="font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                            <p>
                            HIPEROY is your trusted partner in the mining industry, offering a diverse portfolio of high-performance products 
                            designed to meet the rigorous demands of modern mining operations. Our grinding media, including cast alloy, 
                            high chrome, and hyper steel grinding balls, are engineered for maximum durability and efficiency,
                            ensuring optimal performance in grinding applications. We also provide robust mill liners and body 
                            liners that enhance the longevity and productivity of your milling equipment. 
                            Our innovative ball loading and ball sorting systems streamline operations, further boosting efficiency and reducing downtime.
                            </p>
                            <p className="mb-6 sm:mb-20 my-3">
                            In addition to our advanced grinding solutions, HIPEROY offers a wide range of equipment tailored for mining operations.
                            Our crushers, including jaw and cone crushers, are designed for high-capacity, reliable crushing performance. 
                            Our mobile series features track-mounted options like jaw crushers, cone crushers, vertical shaft impactors, 
                            screens, and scalpers, providing versatile, on-the-go solutions for any mining site. 
                            The semi-mobile Profast series delivers flexibility and efficiency, with jaw, cone, and vertical shaft impactor options
                            to suit various operational needs. To keep your equipment running at peak performance, HIPEROY also offers precision-engineered spares,
                            including hammers, jaws, and cones, ensuring reliable and long-lasting operation.
                        </p>
                        </div>
                </div>



                <div className="flex flex-col justify-center items-center font-body md:px-20 lg:px-24 xl:px-64">

                <ExpandableContent
                    title="Grinding Media"
                    content="HIPEROY’s Grinding Media, including high chrome and hyper steel balls, ensure efficient and precise milling in cement production. Designed for durability and wear resistance, they enhance cement quality and improve the efficiency of your manufacturing process."
                    moreInfo={true}
                    href="grinding_media/"
                    />
                <ExpandableContent
                    title="Mill Liners"
                    content="HIPEROY’s Mill Liners are designed to protect your grinding mills and enhance their longevity in cement manufacturing. Built for durability and wear resistance, these liners ensure efficient milling operations, reducing downtime and maintenance costs while contributing to consistent and high-quality cement production."
                    moreInfo={true}
                    href="cement-mill-liners/"
                    />
                <ExpandableContent
                    title="Dip Tube"
                    content="HIPEROY’s Dip Tubes are precision-engineered to optimize the material flow in cement processing, ensuring efficient and consistent production. Designed for durability and high performance, our dip tubes reduce blockages and wear, enhancing the overall efficiency and reliability of your cement manufacturing operations."
                    moreInfo={true}
                    href="dip-tube/"
                    />
                </div>

                <ContactInfo/>


            </div>
            <Footer/>
        </Scaffold>
    )
}