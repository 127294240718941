import React ,{useState} from "react";
import { templates } from "@reactivated";
import { CSRFToken } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "@client/components/Navbar";
import Footer from "@client/components/Footer";
import BannerComponent from "@client/components/Banner";
import Button from "@client/components/Button"


export default (props: templates.Home) => {

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);

  const handleChange = (e:any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  
  const handleSubmit = async (event:any) => {
    event.preventDefault();

    setResponseMessage(null);
  
    try {
      const response = await fetch('/api/send-email/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        const data = await response.json();
        setResponseMessage('Thanks for reaching out! We’ll get back to you soon.');
        setIsError(false);
      } else {
        const errorData = await response.json();
        console.log('Error: ' + JSON.stringify(errorData.errors));
        setResponseMessage('Oops! Something went wrong. Please check the form and try again.');
        setIsError(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage('Sorry, we’re having trouble right now. Please try again later.');
      setIsError(true);
    }
  };
  

    return (
      <Scaffold title="Home">
        <Navbar />
        <BannerComponent />

        <div className="flex flex-col justify-center items-start m-8 sm:m-16 md:m-20 lg:mx-32 md:py-20" id="about-us">
            <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading">
              Who are we?
            </h1>
            <p className="font-body text-sm sm:text-lg leading-7 sm:leading-10  text-start sm:text-justify">
              Hiperoy Solutions is a rising leader in the business solutions
              sector, providing innovative, tailored strategies that help
              industry leaders and enterprises achieve their goals. We combine
              advanced technology with deep industry expertise to create
              customized solutions that tackle each client’s unique challenges.
              Our approach goes beyond quick fixes, emphasizing long-term growth
              and sustainability. Partnering with Hiperoy gives you access to a
              new level of enterprise solutions, where efficiency, reliability,
              and growth are carefully aligned to maximize your business's
              potential. We're dedicated to driving your long-term success by
              providing solutions that address today’s demands while also
              anticipating future opportunities, keeping your business ahead of
              the competition.
            </p>
        </div>

        <div className="my-14">
          <div className="hidden sm:flex flex-col md:flex-row bg-white  border border-[#00000080] border-dotted  rounded-tl-[70px] rounded-tr-[70px] rounded-bl-[70px]">
            <div className="flex justify-center items-center bg-[#E9F0FF] p-12 md:p-16 w-1/3 border-r border-[#00000080] border-dotted rounded-tl-[70px] rounded-tr-[70px] rounded-bl-[70px]">
              <h2 className="text-2xl font-bold text-center font-heading">Our Mission</h2>
            </div>

            <div className="p-8 md:p-16 lg:p-24 xl:p-36 w-2/3 m-0 md:m-10">
              <p className="text-gray-700 leading-7 sm:leading-10 font-body text-sm sm:text-lg text-justify">
                At Hiperoy, our mission is to empower businesses with tailored,
                innovative solutions that address their unique challenges. In
                today’s dynamic business environment, precision and adaptability
                are crucial. Our dedicated team works closely with clients to
                understand their needs, ensuring every solution is effective,
                scalable, and future-proof. We aim to build lasting
                relationships founded on trust, integrity, and a commitment to
                excellence, driving real results that help our clients achieve
                their goals.
              </p>
            </div>
          </div>

          <div className="hidden sm:flex flex-col md:flex-row bg-white  border border-[#00000080] border-dotted  rounded-tr-[70px] rounded-br-[70px]">

            <div className="p-8 md:p-16 lg:p-24 xl:p-36 w-2/3 m-0 md:m-10">
              <p className="text-gray-700 leading-7 sm:leading-10 font-body text-sm sm:text-lg text-justify">
                Our vision is to become the top provider of tailored enterprise
                solutions, known for creating lasting impact and success. We
                strive to lead in innovation, constantly exploring new
                possibilities through technology and strategic insight. By
                staying ahead of industry trends and focusing on quality, we aim
                to develop solutions that address today’s challenges and
                anticipate tomorrow’s. At Hiperoy, we see a future where every
                business, regardless of size or industry, has the tools and
                expertise to thrive, with us as their trusted partner in
                success.
              </p>
            </div>

            <div className="flex justify-center items-center bg-[#FFEFEC] p-12 md:p-16 w-1/3 border-r border-[#00000080] border-dotted rounded-tl-[70px] rounded-tr-[70px] rounded-br-[70px]">
              <h2 className="text-2xl font-bold text-center font-heading">Our Vision</h2>
            </div>
          </div>
        </div>


        <div className="mx-8 sm:mx-10 md:mx-16 lg:px-32 my-10 sm:my-24 sm:hidden">
          <div className="flex flex-col">
            <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading">
              Our Mission
            </h1>
            <p className="mb-6 sm:mb-20 font-body text-start text-sm sm:text-lg leading-7 sm:leading-10">
              At Hiperoy, our mission is to empower businesses by delivering
              precise, innovative solutions that are meticulously crafted to
              meet the unique challenges of each enterprise. We believe that in
              today’s fast-paced and ever-evolving business landscape, the key
              to success lies in precision and adaptability. Our team of
              dedicated professionals is committed to partnering with clients to
              deeply understand their needs and objectives, ensuring that every
              solution we provide is not only effective but also scalable and
              future-proof. We strive to build long-term relationships based on
              trust, integrity, and a shared commitment to excellence, driving
              tangible results that propel our clients toward their goals.
            </p>
          </div>

          <div className="flex flex-col">
            <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading">
              Our Vision
            </h1>
            <p className="mb-6 sm:mb-20 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start">
              Our vision is to become the leading provider of customized
              enterprise solutions, recognized for our ability to drive lasting
              impact and success. We aspire to be at the forefront of
              innovation, continuously pushing the boundaries of what is
              possible through technology and strategic insight. By staying
              ahead of industry trends and maintaining an unwavering focus on
              quality, we aim to create solutions that not only solve today’s
              problems but also anticipate the challenges of tomorrow. At
              Hiperoy, we envision a future where every business, regardless of
              size or industry, has access to the tools and expertise needed to
              thrive in a competitive world, with us as their trusted partner in
              success.
            </p>
          </div>
        </div>


        <div className="flex flex-col mx-4  md:mx-10 my-10 sm:mt-24 space-y-12">
        <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-center">
          Sectors We Serve
        </h1>

        <div className="flex flex-col sm:flex-row  font-body space-y-8 sm:space-y-0 sm:space-x-12 px-0 lg:px-40">
          <div className="flex-1 flex flex-col border border-[#00000080] border-dotted rounded-[30px] sm:rounded-[65px] bg-[#F7FBFB] p-6 sm:p-12  md:p-16  sm:text-left ">
            <h1 className="mb-4 sm:mb-6 text-2xl font-bold font-heading">Mining Processing</h1>
            <p className="font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify" >
              Our cutting-edge grinding media technology boosts mining operations with durable, efficient, and wear-resistant materials. These advanced tools optimize crushing and grinding processes, driving maximum productivity.
            </p>
          </div>

          <div className="flex-1 flex flex-col border border-[#00000080] border-dotted rounded-[30px] sm:rounded-[65px] bg-[#F7FBFB] p-6 sm:p-12  md:p-16  sm:text-left">
            <h1 className="mb-4 sm:mb-6 text-2xl font-bold font-heading">Cement Processing</h1>
            <p className="font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
              Our grinding media delivers precise raw material size reduction. This helps cement manufacturers create top-quality products with greater durability and a longer service life.
            </p>
          </div>
        </div>
      </div>



        <div id="contact-section" className=" my-10 sm:pt-24">

        <div className="flex flex-col items-center justify-center">
          <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading">Reach Out to Us</h1>
          <form method="POST" onSubmit={handleSubmit}  className="p-5 sm:p-0 w-full max-w-md space-y-4 sm:space-y-8 font-body">
            <CSRFToken />
              <input
                type="text"
                className="w-full  py-4 border-b border-[#21212180] focus:outline-none  focus:border-b-[#FC623D] text-sm sm:text-md"
                placeholder="First Name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
              />

              <input
                type="text"
                className="w-full  py-4 border-b border-[#21212180] focus:outline-none  focus:border-b-[#FC623D] text-sm sm:text-md"
                placeholder="Last Name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
              />

              <input
                type="email"
                className="w-full  py-4 border-b border-[#21212180] focus:outline-none  focus:border-b-[#FC623D] text-sm sm:text-md"
                placeholder="Email *"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />

              <input
                type="text"
                className="w-full  py-4 border-b border-[#21212180] focus:outline-none  focus:border-b-[#FC623D] text-sm sm:text-md"
                placeholder="Subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
              />

              <textarea
                className=" block w-full px-3 py-2 border border-[#21212180] rounded-md  focus:outline-none focus:border-[#FC623D] sm:text-sm"
                placeholder="Message"
                rows={6}
                name="message"
                value={formData.message}
                onChange={handleChange}
              />

              <div className="flex flex-col items-center pt-8">
                <Button text="Submit" />
                {responseMessage && (
                  <div className={`p-4 mt-4 w-full max-w-md ${isError ?'text-red-600 italic': 'text-green-600 italic'} text-center`}>
                    {responseMessage}
                  </div>
                )}
              </div>

          </form>


        </div>

          <div className="font-body my-10 ">
            <p className="text-center text-sm sm:text-lg">
              For detailed inquiries, please contact us at
              <p className="text-[#0070E0] ml-2 my-2">sales@hiperoy.com.au.</p>
            </p>

            <p className="text-center text-sm sm:text-lg">
              or reach out to us at : +61 - 419 532 641{" "}
            </p>
          </div>
        </div>


        <Footer />
      </Scaffold>
    );
}