import React, { useState } from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import GrindingBallsBanner from "../components/GrindingBallsBanner";
import ContactInfo from "@client/components/ContactInfo";
import ExpandableContent from "@client/components/ExpandableContent";



export default (props: templates.GrindingBalls) => { 
    return (
        <Scaffold title="Grinding balls">
            <Navbar/>
            <GrindingBallsBanner/>

            <div className="mx-8 sm:mx-10 md:mx-16 lg:px-32 my-10 sm:my-20">

                <div className="flex flex-col">
                    <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">Key Features</h1>
                    <ul className="mb-6 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                        <li className="list-disc">High Wear Resistance: Designed to endure intense grinding conditions, these balls significantly extend the lifespan of your milling equipment, reducing maintenance frequency.</li>
                        <li className="list-disc">Superior Hardness: Engineered for maximum hardness, they ensure efficient ore reduction, boosting productivity and improving the overall efficiency of the grinding process.</li>
                        <li className="list-disc">Corrosion Resistance: Built to resist corrosion, especially in harsh environments with high moisture or chemical exposure, ensuring long-lasting performance and reliability.</li>
                        <li className="list-disc">Consistent Performance: Provides uniform size reduction and efficient milling, contributing to stable operations and consistent output quality.</li>
                        <li className="list-disc">Extended Service Life: The robust construction and wear-resistant properties lead to a longer service life, minimizing the need for frequent replacements and reducing operational downtime.</li>
                        <li className="list-disc">Versatility: Adaptable to a wide range of ore types and grinding conditions, making them suitable for various stages of the mineral processing cycle and enhancing overall operational flexibility.</li>
                    </ul>
                </div>

                <div className="my-16">
                    <div className="mb-16">
                        <h3 className="mb-6 sm:mb-12 text-xl font-bold font-heading">Grade of Cast Alloy Grinding Balls</h3>
                        <div>
                            <img
                            src={"/assets/products/GringinBallGrade.svg"}
                            className="w-full h-full"
                            />  
                        </div>
                    </div>

                    <div className="my-16">
                        <h3 className="mb-6 sm:mb-12 text-xl font-bold font-heading">Grade and Physical aspects of Cast Alloy Grinding Balls</h3>
                        <div>
                            <img
                                src={"/assets/products/BallsPhysicalFeature.svg"}
                                className="w-full h-full"
                                />  
                        </div>
                    </div>

                    <div className="mt-16">
                        <h3 className="mb-6 sm:mb-12 text-xl font-bold font-heading">Size Tolerances of Cast Alloy Grinding Balls</h3>
                        <div>
                            <img
                                src={"/assets/products/BallsSize.svg"}
                                className="w-full h-full"
                                />  
                        </div>
                    </div>
                </div>


                <div className="flex flex-col justify-center items-center font-body md:px-20 lg:px-24 xl:px-64">
                    <ExpandableContent
                        title="Cast Alloy"
                        content="HIPEQY's cast alloy grinding media is engineered to withstand the toughest mining conditions, 
                        offering exceptional durability and wear resistance. These robust grinding balls ensure efficient 
                        ore processing and reliable performance, contributing to higher productivity and extended equipment life in mining operations."
                    />
                    <ExpandableContent
                        title="High Chrome"
                        content="HIPEROY’s High Chrome Grinding Balls are engineered for superior hardness and wear resistance,
                        ensuring efficient and consistent milling in cement production. 
                        These balls enhance grinding efficiency and contribute to improved cement quality and production reliability."
                    />

                    <ExpandableContent
                    title="Hyper Steel"
                    content="HIPEROY’s Hyper Steel Grinding Balls are designed for durability and toughness, 
                    providing reliable and efficient milling in cement production. These balls offer excellent wear resistance,
                     ensuring consistent grinding performance and contributing to the overall quality and efficiency of your cement manufacturing process."
                    />

                </div>


                <ContactInfo/>
            </div>

            <Footer/>
        </Scaffold>
    )
}