import React, { useRef } from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";
import ExpandableContent from "../components/ExpandableContent";

export default (props: templates.SemiMobileSeries) => {
  const scrollContainerRef = useRef(null);
  return (
    <Scaffold title="Semi Mobile Series">
      <Navbar scrollContainerRef={scrollContainerRef} />

      <div
        ref={scrollContainerRef}
        className="h-screen overflow-y-scroll sm:snap-y sm:snap-mandatory pt-0 sm:pt-20 scroll-smooth"
      >
        <section className="snap-start">
          <div className="relative">
            <div className="hidden sm:block">
              <div className="relative w-full h-screen">
                <img
                  src={"/assets/products/SemiMobileSeries.svg"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              <div className="flex flex-col justify-start item-start absolute top-[16%] sm:top-[40%] left-[4%] sm:left-[10%] w-[80%]">
                <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl mb-0 sm:mb-5">
                  Semi Mobile Series
                </h1>
                <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block mb-0 sm:mb-10">
                  Products / Machinery / Semi Mobile Series
                </p>

                <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                  HIPEROY’s Semi Mobile Series offers a blend of mobility and
                  stability, providing flexible crushing and screening solutions
                  that can be easily relocated while offering the robust
                  performance of stationary units. Ideal for medium to
                  large-scale mining operations, these systems are designed for
                  efficient material processing with the capability to adapt to
                  changing site conditions, ensuring reliable operation and high
                  productivity.{" "}
                </p>
              </div>
            </div>
            <div className="block sm:hidden">
              <div className="relative w-full h-full">
                <img
                  src={"/assets/products/SemiMob.svg"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              <div className="absolute inset-0 flex flex-col justify-center items-center text-white mx-10">
                <h1 className="font-heading font-normal text-2xl text-center">
                  Semi Mobile Series
                </h1>
                <p className="font-body text-sm mt-5 text-center">
                  The semi-mobile series machinery combines flexibility with
                  power, providing efficient crushing and screening solutions
                  that can be easily relocated while maintaining high
                  performance.{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="snap-start">
          <div className="mx-12 sm:mx-10 md:mx-16 lg:px-32 pt-0 sm:pt-24">
            <div className="flex flex-col">
              <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">
                Key Features
              </h1>
              <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                <li className="list-disc">
                  Flexibility: Merges mobility with stability, enabling easy
                  relocation and adaptability to different mining sites while
                  ensuring strong performance.
                </li>
                <li className="list-disc">
                  Durable Construction: Built tough to handle challenging
                  conditions, ensuring reliable performance over the long term.
                </li>
                <li className="list-disc">
                  Cost-Effective: Strikes a balance between stationary and
                  mobile units, cutting setup and relocation costs.
                </li>
                <li className="list-disc">
                  Ease of Setup: Allows for faster installation and
                  commissioning compared to fully stationary systems.
                </li>
              </ul>
            </div>

            <div className="flex flex-col justify-center items-center font-body md:px-20 lg:px-24 xl:px-64">
              <ExpandableContent
                title="Semi Mobile Jaw"
                moreInfo={false}
                content="Our Semi-Mobile Jaw Crusher, is engineered for efficient ore crushing in the primary stage of mining operations. Combining mobility with stability, it enables quick setup and reliable performance in mining processing. Built for durability and high capacity, it ensures consistent, high-quality output in various mining environments, optimizing productivity and efficiency."
              />
              <ExpandableContent
                title="Semi Mobile Vertical Shaft Impactor"
                moreInfo={false}
                content="Our Semi Mobile Vertical Shaft Impactor (VSI), facilitates swift installation and relocation, making it ideal for a variety of mining environments. Engineered for durability and peak performance, it ensures to drive in success in your mining projects."
              />
              <ExpandableContent
                title="Semi Mobile Cone"
                moreInfo={false}
                content="Our Semi-Mobile Cone Crusher, offers high-efficiency ore crushing in both secondary and tertiary stages. Its design allows for easy relocation and quick setup, ensuring reliable performance in diverse mining environments. With high-capacity crushing and consistent, top-quality output, it boosts productivity and reduces downtime, for optimizing mining operations."
              />
            </div>
          </div>

          <ContactInfo />
          <Footer />
        </section>
      </div>
    </Scaffold>
  );
};
