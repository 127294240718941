import React, { useRef } from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";
import Carousel from "@client/components/Carousel";

export default (props: templates.BallSorting) => {
  const scrollContainerRef = useRef(null);

  const ballSortingContentimg = [
    "/assets/products/BallSortingContent.svg",
    "/assets/products/BallSortingContent1.svg",
  ];
  return (
    <Scaffold title="Ball Sorting">
      <Navbar scrollContainerRef={scrollContainerRef} />

      <div
        ref={scrollContainerRef}
        className="h-screen overflow-y-scroll sm:snap-y sm:snap-mandatory pt-0 sm:pt-20 scroll-smooth"
      >
      <section className="snap-start">
        <div className="relative">
          <div className="hidden sm:block">
            <div className="relative w-full h-screen">
              <img
                src={"/assets/products/BallSorting.svg"}
                alt="background"
                className="w-full h-full object-cover relative z-[-100]"
              />
              <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
              <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
            </div>

            <div className="flex flex-col justify-start item-start absolute top-[16%] sm:top-[40%] left-[4%] sm:left-[10%] w-[80%]">
              <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl mb-0 sm:mb-5">
                Ball Sorting
              </h1>
              <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block mb-0 sm:mb-10">
                Products / Ball Sorting
              </p>

              <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                HIPEROY Mining Solutions designs top-notch grinding media
                sorting equipment known for outstanding reliability and
                performance. This machine operates flawlessly and is easy to
                move with an optional trolley. It excels at removing charge
                impurities and provides precise ball categorization, surpassing
                manual sorting methods to boost efficiency and accuracy.
              </p>
            </div>
          </div>
          <div className="block sm:hidden">
            <div className="relative w-full h-full">
              <img
                src={"/assets/products/BallSortingMob.svg"}
                alt="background"
                className="w-full h-full object-cover relative z-[-100]"
              />
              <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
            </div>

            <div className="absolute inset-0 flex flex-col justify-center items-center text-white mx-10">
              <h1 className="font-heading font-normal text-2xl">
                Ball Sorting
              </h1>
              <p className="font-body text-sm mt-5 text-center">
                The ball sorting machine ensures precise classification of
                grinding media, improving milling efficiency and performance.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="snap-start">
        <div className="mx-12 sm:mx-10 md:mx-16 lg:px-32 pt-0 sm:pt-24">
          <div className="flex flex-col">
            <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">
              Features
            </h1>

            <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
              <li className="list-disc">
                The ball sorting machine handles grinding balls of ∅ 90-60 mm at
                6-8 t/h and ∅ 50-15 mm at 3-5 t/h.
              </li>
              <li className="list-disc">
                Includes a heliworm geared motor (1.5 HP x 2), roller assembly
                with two rollers, a vibrating feeder assembly, and an electrical
                panel powered by a 380V, 50Hz supply.
              </li>
              <li className="list-disc">
                The overall machine dimensions are approximately 6020 x 1200 x
                2400 mm, with a hopper size of 2000 x 2200 mm and seven
                discharge chutes.
              </li>
            </ul>
          </div>

          <div className="flex justify-center w-full h-full my-10 sm:my-0">
            <Carousel images={ballSortingContentimg} />
          </div>
        </div>

        <ContactInfo />
        <Footer />
      </section>
      </div>
    </Scaffold>
  );
};
