import React from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";


export default (props: templates.BallSorting) => {
    return (
        <Scaffold title="Ball Sorting">
            <Navbar/>

            <div>
                <div className="relative w-full h-full">
                    <img
                    src={"/assets/products/BallSorting.svg"}
                    alt="background"
                    className="w-full h-full object-cover relative z-[-10]"
                    />
                    <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                    <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
                </div>


                <div className="flex flex-col justify-start item-start space-y-2 sm:space-y-4 md:space-y-6 absolute md:w-[78%] lg:w-[70%]  top-[20%] sm:top-[30%] md:top-[30%] lg:[top-50%] left-[8%] md:[left-9%]">
                <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl">
                  Ball Sorting
                    </h1>
                    <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block">Products / Mining / Ball Sorting</p>

                    <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                    HIPEROY Mining Solutions designs top-notch grinding media sorting equipment known for outstanding reliability and performance. This machine operates flawlessly and is easy to move with an optional trolley. It excels at removing charge impurities and provides precise ball categorization, surpassing manual sorting methods to boost efficiency and accuracy.
                    </p>

                </div>
            </div>

            <div className="mx-8 sm:mx-10 md:mx-16 lg:px-32 my-10 sm:my-20">
                <div className="flex flex-col">
                    <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">Features</h1>

                    <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                        <li className="list-disc">Efficient Loading: Streamlines the process of feeding grinding media balls into ball mills, improving overall efficiency.</li>
                        <li className="list-disc">Mobile Design: Equipped with mobility features for easy positioning and operation in different locations within a facility.</li>
                        <li className="list-disc">Accurate Distribution: Ensures precise and uniform distribution of grinding media, enhancing milling effectiveness.</li>
                        <li className="list-disc">High Capacity: Capable of handling large volumes of grinding media, suitable for high-demand processing environments.</li>
                        <li className="list-disc">Automated Operation: Often includes automation features to reduce manual labor and increase operational consistency.</li>
                        <li className="list-disc">Durable Construction: Built with robust materials to withstand the harsh conditions of industrial environments.</li>
                    </ul>
                </div>

                <div className="flex justify-center w-full h-full"> 
                    <img
                        src={"/assets/products/BallSortingContent.svg"}
                        alt="background"
                        className="w-1/2 h-1/2 object-cover mx-auto"  
                    />
                </div>
            </div>



            <ContactInfo/>
            <Footer/>
        </Scaffold>
    )
}