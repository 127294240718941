import React, { MouseEventHandler } from 'react';

interface IButtonProps {
    text: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
  }

const CustomButton = (props: IButtonProps) => {
  return (
    <button 
    className="bg-[#FC623D] text-white font-heading font-normal text-sm sm:text-md py-3 sm:py-5 px-6 sm:px-12 rounded-lg hover:drop-shadow-lg"
    >
        {props.text}
    </button>
  );
};

export default CustomButton;