import React from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";
import ExpandableContent from "../components/ExpandableContent";


export default (props: templates.GrindingMedia) => {
    return (
        <Scaffold title="Grinding Media">
            <Navbar/>

            <div>
                <div className="relative w-full h-full">
                    <img
                    src={"/assets/products/Balls.svg"}
                    alt="background"
                    className="w-full h-full object-cover relative z-[-10]"
                    />
                    <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                    <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
                </div>


                <div className="flex flex-col justify-start item-start space-y-2 sm:space-y-4 md:space-y-6 absolute md:w-[78%] lg:w-[70%]  top-[20%] sm:top-[30%] md:top-[30%] lg:[top-50%] left-[8%] md:[left-9%]">
                    <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl">
                    Grinding Media
                    </h1>
                    <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block">Products / Cement / Grinding Media</p>

                    <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                    Our grinding media for cement delivers efficiency and effectiveness. Engineered for hardness and wear resistance, our high chrome and hyper steel balls ensure superior grinding performance and lower energy consumption. Ideal for cement mills, our media guarantees consistent material reduction and enhanced productivity.</p>                </div>
            </div>

            <div className="mx-8 sm:mx-10 md:mx-16 lg:px-32 my-10 sm:my-20">

                <div className="flex flex-col">
                    <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">Key Features</h1>
                    <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                        <li className="list-disc">High Hardness: Ensures superior durability and resistance to wear, extending the lifespan of the grinding media.</li>
                        <li className="list-disc">Enhanced Wear Resistance: Designed to withstand abrasive conditions, reducing the frequency of replacements.</li>
                        <li className="list-disc">Efficient Grinding: Improves grinding performance and reduces energy consumption, optimizing cement production.</li>
                        <li className="list-disc">Consistent Quality: Provides uniform size reduction for consistent product quality and performance.</li>
                        <li className="list-disc">Versatile Application: Suitable for various types of cement mills, adapting to different grinding conditions.</li>
                        <li className="list-disc">Extended Equipment Life: Helps to prolong the life of milling equipment by minimizing wear and tear.</li>
                    </ul>
                </div>

            <div className="flex flex-col justify-center items-center font-body md:px-20 lg:px-24 xl:px-64">

                <ExpandableContent
                    title="High Chrome"
                    content="HIPEROY’s High Chrome Grinding Balls are engineered for superior hardness and wear resistance, ensuring efficient and consistent milling in cement production. These balls enhance grinding efficiency and contribute to improved cement quality and production reliability."
                    moreInfo={false}
                />
                <ExpandableContent
                    title="Hyper Steel"
                    content="HIPEROY’s Hyper Steel Grinding Balls are designed for durability and toughness, providing reliable and efficient milling in cement production. These balls offer excellent wear resistance, ensuring consistent grinding performance and contributing to the overall quality and efficiency of your cement manufacturing process."
                    moreInfo={false}
                />


            </div>
            
            </div>


            <ContactInfo/>
            <Footer/>
        </Scaffold>
    )
}