import React, { useRef } from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";
import ExpandableContent from "../components/ExpandableContent";

export default (props: templates.Crushers) => {
  const scrollContainerRef = useRef(null);
  return (
    <Scaffold title="Crushers">
      <Navbar scrollContainerRef={scrollContainerRef} />

      <div
        ref={scrollContainerRef}
        className="h-screen overflow-y-scroll sm:snap-y sm:snap-mandatory pt-0 sm:pt-20 scroll-smooth"
      >
        <section className="snap-start">
          <div className="relative">
            <div className="hidden sm:block">
              <div className="relative w-full h-screen">
                <img
                  src={"/assets/products/Crushers.svg"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              <div className="flex flex-col justify-start item-start absolute top-[16%] sm:top-[40%] left-[4%] sm:left-[10%] w-[80%]">
                <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl mb-0 sm:mb-5">
                  Crushers
                </h1>
                <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block mb-0 sm:mb-10">
                  Products / Machinery / Crushers
                </p>

                <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                  Our crushers are built to handle tough mining tasks,
                  delivering powerful performance for primary, secondary, and
                  tertiary stage of ore crushing. Made with top-grade materials
                  and innovative design, they ensure effective material
                  reduction, high throughput, and long service life, minimizing
                  downtime and maintenance needs.
                </p>
              </div>
            </div>

            <div className="block sm:hidden">
              <div className="relative w-full h-full">
                <img
                  src={"/assets/products/CrushersMob.svg"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              {/* Text content positioned over the image */}
              <div className="absolute inset-0 flex flex-col justify-center items-center text-white mx-10">
                <h1 className="font-heading font-normal text-2xl">Crushers</h1>
                <p className="font-body text-sm mt-5 text-center">
                  Our crushers provide powerful and efficient material reduction
                  for various mining and industrial applications.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="snap-start">
          <div className="mx-12 sm:mx-10 md:mx-16 lg:px-32 pt-0 sm:pt-24">
            <div className="flex flex-col">
              <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">
                Key Features
              </h1>
              <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                <li className="list-disc">
                  Versatile Application: Perfect for any stage of ore processing
                  (primary, secondary, or tertiary), our crushers adapt to
                  various ore types.
                </li>
                <li className="list-disc">
                  Reduced Maintenance: Designed for simple upkeep, our crushers
                  cut downtime and lower operational costs, keeping your
                  operations running smoothly.
                </li>
                <li className="list-disc">
                  Extended Service Life: With a robust build, our crushers
                  provide long-lasting performance and dependable results in the
                  most demanding mining settings.
                </li>
              </ul>
            </div>

            <div className="flex flex-col justify-center items-center font-body md:px-20 lg:px-24 xl:px-64">
              <ExpandableContent
                title="Jaw Crushers"
                content="HIPEROY’s Jaw Crushers are built for power and longevity, expertly handling large volumes of material. Engineered for high-capacity primary stage of ore crushing, they provide dependable performance and boost productivity, even in the toughest mining conditions."
                imageUrl="/assets/products/JawCrushers.svg"
              />
              <ExpandableContent
                title="Cone Crushers"
                content="Our Cone Crushers are built for peak efficiency and long-lasting durability. They excel in secondary and tertiary stage of ore crushing, handling diverse material sizes with ease. These crushers ensure reliable, consistent performance, boosting productivity in mining operations."
                moreInfo={false}
                imageUrl="/assets/products/ConeCrushers.svg"
              />
              <ExpandableContent
                title="Impact Crushers"
                moreInfo={false}
                additionalInfo={
                  <div className=" p-4 sm:p-10">
                    <div className="">
                      <h2 className="font-heading">Vertical Shaft Impactor</h2>
                      <p className="text-justify pt-4 font-body leading-8">
                        The Vertical Shaft Impactor is engineered with insights
                        from aggregate producers. Our high-velocity VSI crusher
                        enhances aggregate shape and optimizes surface texture,
                        making it ideal for a wide range of materials.
                      </p>
                      <div>
                        <img
                          src="/assets/products/VerticalShiftImpactor.svg"
                          className="max-w-full h-auto rounded-md"
                        />
                      </div>
                    </div>
                    <div className="">
                      <h2 className="font-heading ">
                        Horizontal Shaft Impactor
                      </h2>
                      <p className="text-justify pt-4 font-body leading-8">
                        The Horizontal Shaft Impactor offers exceptional
                        reliability with its efficient belt drive and hydraulic
                        gap management system. It is designed to handle
                        materials like limestone, dolomite, gravel, glass,
                        sandstone, salt, gypsum, and potash.
                      </p>
                      <div>
                        <img
                          src="/assets/products/HorizontalShiftImpactor.svg"
                          className="max-w-full h-auto rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
            <ContactInfo />
          </div>

          <Footer />
        </section>
      </div>
    </Scaffold>
  );
};
