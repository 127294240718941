import React from "react";

const Banner = () => {
  return (
    <div className="relative">
      <div className="hidden sm:block">
        <div className="relative w-full h-screen">
          <img
            src={"/assets/products/HomeBg.png"}
            alt="background"
            className="w-full h-full object-cover relative z-[-100]"
          />
          <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
        </div>
        <div className="flex flex-col justify-start item-start absolute top-[16%] sm:top-[40%] lg:top-[50%] left-[4%] sm:left-[10%]">
          <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl mb-0 sm:mb-5">
            Hiperoy Solutions
          </h1>
          <p className="font-body text-sm sm:text-xl text-white">
            Empowering enterprises through precise solutions.
          </p>
        </div>
      </div>

      <div className="block sm:hidden">
        <div className="relative w-full h-[50%]">
          <img
            src={"/assets/products/HomeBgMob.png"}
            alt="background"
            className="w-full h-full object-cover relative z-[-100]"
          />
          <div className="absolute inset-0 h-2/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
        </div>

        <div className="absolute inset-0 flex flex-col justify-center items-center text-white mx-10">
          <h1 className="font-heading font-normal text-2xl text-center">
            Hiperoy Solutions
          </h1>
          <p className="font-body text-sm mt-5 text-center">
            Empowering enterprises through precise solutions.
          </p>
        </div>
      </div>


    </div>
  );
};

export default Banner;
