import React from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";
import ExpandableContent from "../components/ExpandableContent";


export default (props: templates.SemiMobileSeries) => {
    return (
        <Scaffold title="Semi Mobile Series">
            <Navbar/>

            <div>
                <div className="relative w-full h-full">
                    <img
                    src={"/assets/products/SemiMobileSeries.svg"}
                    alt="background"
                    className="w-full h-full object-cover relative z-[-10]"
                    />
                    <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                    <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
                </div>


                <div className="flex flex-col justify-start item-start space-y-2 sm:space-y-4 md:space-y-6 absolute md:w-[78%] lg:w-[70%]  top-[20%] sm:top-[30%] md:top-[30%] lg:[top-50%] left-[8%] md:[left-9%]">
                    <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl">
                    Semi Mobile Series
                    </h1>
                    <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block">Products / Mining / Equipment / Semi Mobile Series</p>

                    <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                    HIPEROY’s Semi Mobile Series offers a blend of mobility and stability, providing flexible crushing and screening solutions that can be easily relocated while offering the robust performance of stationary units. Ideal for medium to large-scale mining operations, these systems are designed for efficient material processing with the capability to adapt to changing site conditions, ensuring reliable operation and high productivity.                    </p>

                </div>
            </div>

            <div className="mx-8 sm:mx-10 md:mx-16 lg:px-32 my-10 sm:my-20">

                <div className="flex flex-col">
                    <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">Key Features</h1>
                    <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                        <li className="list-disc">
                        Flexibility: Combines mobility with stability, allowing for relocation and adaptation to different mining sites while maintaining robust performance.</li>
                        <li className="list-disc">Efficient Processing: Designed for high productivity, providing effective crushing and screening with minimal downtime.</li>
                        <li className="list-disc">Durable Construction: Built to withstand challenging conditions while ensuring long-term reliability and performance.</li>
                        <li className="list-disc">Cost-Effective: Offers a balance between the benefits of stationary and mobile units, reducing setup and relocation costs.</li>
                        <li className="list-disc">Ease of Setup: Facilitates quicker installation and commissioning compared to fully stationary systems.</li>
                        <li className="list-disc">Versatility: Suitable for various operational scales and site conditions, adapting to changing mining needs.</li>
                    </ul>
                </div>

            <div className="flex flex-col justify-center items-center font-body md:px-20 lg:px-24 xl:px-64">

                <ExpandableContent
                    title="Profast Jaw"
                    content="HIPEROY’s Profast Jaw, part of our Semi-Mobile Series, delivers robust performance for primary crushing in mining operations. Its semi-mobile design offers both mobility and stability, enabling efficient and reliable material processing with easy setup. Engineered for durability and high capacity, the Profast Jaw ensures consistent, high-quality output in various mining environments."
                    moreInfo={false}
                />
                <ExpandableContent
                    title="Profast Vertical Shaft Impactor"
                    content="HIPEROY’s Profast Vertical Shaft Impactor, from our Semi-Mobile Series, provides efficient impact crushing with both mobility and stability. Designed for durability and high performance, it delivers reliable, high-quality results across various mining applications."
                    moreInfo={false}
                />
                <ExpandableContent
                    title="Profast Cone"
                    content="HIPEROY’s Profast Cone, part of our Semi-Mobile Series, provides high-efficiency secondary and tertiary crushing. Its semi-mobile design combines mobility with stability, allowing for versatile and reliable operation. Engineered for durability and performance, the Profast Cone ensures consistent, high-quality results and enhances productivity in diverse mining environments."
                    moreInfo={false}
                />



            </div>
            
            </div>


            <ContactInfo/>
            <Footer/>
        </Scaffold>
    )
}