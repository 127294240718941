import React, { useState , useEffect} from "react";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
      const handleScroll = () => {
        setScrollPosition(window.scrollY);
      };
  
      window.addEventListener("scroll", handleScroll);
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    return(
        <nav
        className={`flex justify-between items-center w-full fixed left-1/2 translate-x-[-50%] px-4 md:px-16 lg:px-36 h-10 sm:h-20 z-50 
          ${scrollPosition > 200 ? "bg-white shadow-lg" : "bg-white sm:bg-transparent"} transition-colors duration-300 ease-in-out`}
      >
            <div>
                <a  href={"/"}>
                    <img
                    src={"/assets/icons/Logo.svg"}
                    className="w-[50%] h-[50%] sm:w-full sm:h-full"
                    />  
                </a>
            </div>

            <ul
                className={`sm:flex md:gap-8 lg:gap-28 hidden font-heading text-sm font-normal 
                ${scrollPosition > 50 ? "text-black" : "text-white"} transition-colors duration-300 ease-in-out`}
            >
                <a href={"/"} className="hover:text-[#FC623D] "><li>Home</li></a>
          
                <div className="relative group">
                    <a href={"/product"} className="hover:text-[#FC623D]"><li>Products</li></a>
                    
                    {/* Submenu */}
                    <ul className={`absolute left-0 top-full rounded-xl p-2 px-4 transition-opacity duration-300 whitespace-nowrap
                      ${scrollPosition > 200 ? "bg-white shadow-lg" : "bg-[#04040520]"} opacity-0 group-hover:opacity-100`}>
                      <li className={`my-2 ${scrollPosition > 200 ? "hover:text-black" : "hover:text-white"} text-[#565657]`}>Mining</li>
                      <ul className="ml-4">
                        <li className={`mb-3 ${scrollPosition > 200 ? "hover:text-black" : "hover:text-white"} text-[#565657]`}><a href="/product/grinding-balls/">Grinding Balls</a></li>
                        <li className={`mb-3 ${scrollPosition > 200 ? "hover:text-black" : "hover:text-white"} text-[#565657]`}><a href="/product/ball-loading/">Ball Loading</a></li>
                        <li className={`mb-3 ${scrollPosition > 200 ? "hover:text-black" : "hover:text-white"} text-[#565657]`}><a href="/product/ball-sorting/">Ball Sorting</a></li>
                        <li className={`mb-3 text-xs ${scrollPosition > 200 ? "hover:text-black" : "hover:text-white"} text-[#565657]`}><a href={"/product"}>Explore more →</a></li>
                      </ul>
                      <li className={`my-2 ${scrollPosition > 200 ? "hover:text-black" : "hover:text-white"} text-[#565657]`}>Cement</li>
                      <ul className="ml-4">
                        <li className={`mb-3 ${scrollPosition > 200 ? "hover:text-black" : "hover:text-white"} text-[#565657]`}><a href="/product/cement-mill-liners/">Mill Liners</a></li>
                        <li className={`mb-3 text-xs ${scrollPosition > 200 ? "hover:text-black" : "hover:text-white"} text-[#565657]`}><a href={"/product"}>Explore more →</a></li>
                      </ul>
                    </ul>
                </div>

                <a href="/#about-us" className="hover:text-[#FC623D] "><li>About</li></a>
                <a href="/#contact-section"className="hover:text-[#FC623D] "><li>Contact Us</li></a>
            </ul>

            <button onClick={handleClick} className="sm:hidden">
            <img
              src={"/assets/icons/hamB.svg"}
              className="w-[60%] h-[60%] sm:w-full sm:h-full"
              />
            </button>
            <ul
                className={`bg-white flex flex-col font-heading text-xs font-normal  justify-center items-center gap-y-5 py-4  absolute top-full shadow-xl left-0 w-full 
                  sm:hidden transition-transform transform ${isOpen ? "translate-y-0" : "translate-y-[-200%]"}`}
            >
                <a href={"/"} className="hover:text-[#FC623D] text-black"><li>Home</li></a>
                <a href={"/product"} className="hover:text-[#FC623D] text-black"><li>Products</li></a>
                <a href="#about-us" className="hover:text-[#FC623D] text-black"><li>About</li></a>
                <a href="#contact-section" className="hover:text-[#FC623D] text-black"><li>Contact Us</li></a>
            </ul>
        </nav>
    )
}

export default Navbar;
