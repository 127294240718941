import React from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";
import ExpandableContent from "../components/ExpandableContent";


export default (props: templates.Spares) => {
    return (
        <Scaffold title="Spares">
            <Navbar/>

            <div>
                <div className="relative w-full h-full">
                    <img
                    src={"/assets/products/Spares.svg"}
                    alt="background"
                    className="w-full h-full object-cover relative z-[-10]"
                    />
                    <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                    <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
                </div>


                <div className="flex flex-col justify-start item-start space-y-2 sm:space-y-4 md:space-y-6 absolute md:w-[78%] lg:w-[70%]  top-[20%] sm:top-[30%] md:top-[30%] lg:[top-50%] left-[8%] md:[left-9%]">
                    <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl">
                    Spares
                    </h1>
                    <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block">Products / Mining / Spares</p>

                    <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                    We offer a range of high-quality spares to keep your mining and cement equipment running at peak performance. Our parts, including hammers, jaws, and cones, are built for durability and dependability, minimizing downtime and ensuring smooth, efficient operations.
                    </p>
                </div>
            </div>

            <div className="mx-8 sm:mx-10 md:mx-16 lg:px-32 my-10 sm:my-20">

                <div className="flex flex-col justify-center items-center">
                    <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading">Key Features</h1>
                    <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg text-justify leading-5 sm:leading-8">
                        <li className="list-disc">High Durability: Engineered to withstand intense operational conditions, ensuring long-lasting performance.</li>
                        <li className="list-disc">Precision Manufacturing: Crafted to exact specifications, providing a perfect fit and optimal functionality.</li>
                        <li className="list-disc">Reliable Performance: Designed to enhance the efficiency and reliability of your equipment, minimizing operational disruptions.</li>
                        <li className="list-disc">Quality Materials: Made from high-grade materials to meet stringent quality standards and resist wear and tear.</li>
                        <li className="list-disc">Extended Equipment Life: Helps prolong the lifespan of your machinery by maintaining peak performance.</li>
                        <li className="list-disc">Quick Availability: Ensures timely delivery of essential parts to minimize downtime and keep operations running smoothly.</li>
                    </ul>
                </div>

            <div className="flex flex-col justify-center items-center font-body md:px-20 lg:px-24 xl:px-64">

                <ExpandableContent
                    title="Hammer"
                    content="HIPEROY’s hammer spares are engineered for durability and precision, ensuring efficient crushing and minimal wear in mining and cement processing. Designed to withstand heavy impacts, these hammers enhance equipment performance and extend service life."
                    moreInfo={false}
                />
                <ExpandableContent
                    title="Jaw"
                    content="HIPEROY’s jaw spares are crafted for high durability and reliability, ensuring efficient crushing in demanding mining and cement operations. These spares are designed to enhance equipment performance, reduce downtime, and extend the service life of your jaw crushers."
                    moreInfo={false}
                />
                <ExpandableContent
                    title="Cone"
                    content="HIPEROY’s cone spares are designed for optimal durability and precision, ensuring consistent and efficient crushing in mining and cement applications. These spares help maintain peak performance and extend the lifespan of your cone crushers."
                    moreInfo={false}
                />


            </div>
            
            </div>


            <ContactInfo/>
            <Footer/>
        </Scaffold>
    )
}