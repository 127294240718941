import React from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";
import ExpandableContent from "../components/ExpandableContent";


export default (props: templates.Crushers) => {
    return (
        <Scaffold title="Crushers">
            <Navbar/>

            <div>
                <div className="relative w-full h-full">
                    <img
                    src={"/assets/products/Crushers.svg"}
                    alt="background"
                    className="w-full h-full object-cover relative z-[-10]"
                    />
                    <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                    <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
                </div>


                <div className="flex flex-col justify-start item-start space-y-2 sm:space-y-4 md:space-y-6 absolute md:w-[78%] lg:w-[70%]  top-[20%] sm:top-[30%] md:top-[30%] lg:[top-50%] left-[8%] md:[left-9%]">
                    <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl">
                    Crushers
                    </h1>
                    <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block">Products / Mining / Equipment / Crushers</p>

                    <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                    Our crushers are built to handle tough mining tasks, delivering powerful performance for primary, secondary, and tertiary stage of ore crushing. Made with top-grade materials and innovative design, they ensure effective material reduction, high throughput, and long service life, minimizing downtime and maintenance needs.
                    </p>

                </div>
            </div>

            <div className="mx-8 sm:mx-10 md:mx-16 lg:px-32 my-10 sm:my-20">

                <div className="flex flex-col">
                    <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">Key Features</h1>
                    <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                        <li className="list-disc">Durable Construction: Built with high-strength materials to withstand the rigors of mining operations.</li>
                        <li className="list-disc">High Throughput: Engineered for efficient material reduction, ensuring maximum productivity.</li>
                        <li className="list-disc">Versatile Application: Suitable for primary, secondary, and tertiary crushing of various ore types .</li>
                        <li className="list-disc">Advanced Engineering: Incorporates innovative design elements for consistent performance and reliability.</li>
                        <li className="list-disc">Reduced Maintenance: Designed for easy maintenance, minimizing downtime and lowering operational costs</li>
                        <li className="list-disc">Extended Service Life: Robust design ensures long-lasting performance in demanding mining environments.</li>
                    </ul>
                </div>

            <div className="flex flex-col justify-center items-center font-body md:px-20 lg:px-24 xl:px-64">

                <ExpandableContent
                    title="Jaw"
                    content="HIPEROY’s jaw spares are crafted for high durability and reliability, ensuring efficient crushing in demanding mining and cement operations. These spares are designed to enhance equipment performance, reduce downtime, and extend the service life of your jaw crushers."
                    moreInfo={false}
                />
                <ExpandableContent
                    title="Cone"
                    content="HIPEROY’s cone spares are designed for optimal durability and precision, ensuring consistent and efficient crushing in mining and cement applications. These spares help maintain peak performance and extend the lifespan of your cone crushers."
                    moreInfo={false}
                />

            </div>
            
            </div>


            <ContactInfo/>
            <Footer/>
        </Scaffold>
    )
}