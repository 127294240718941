import React, { useState } from 'react';

interface IExpandableContentProps{
    title : String
    content : String
    moreInfo? : Boolean
    href?: string; 
}

const ExpandableContent = ({ title, content, moreInfo, href } : IExpandableContentProps) => {
  const [showContent, setShowContent] = useState(false);

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  return (
      <div className="shadow-square-shadow text-center text-sm sm:text-lg rounded-xl text-center my-6 py-6">
        <h2 className="font-bold font-heading cursor-pointer relative hover:text-[#FC623D] my-2" onClick={toggleContent}>
          {title}
          <div
            className={`absolute left-0 w-full h-1 transition-opacity duration-300 ${
              showContent ? 'opacity-100' : 'opacity-0'
            }`}
          />
        </h2>
        <div
          className={`transition-max-height ease-in-out duration-300 overflow-hidden ${
            showContent ? 'max-h-auto opacity-100' : 'max-h-0 opacity-0'
          }`}
        >
          <div className="m-6 border-t-2 border-dotted border-[#FC623D80]"></div>
          <p className="text-center p-4 sm:p-10 font-body leading-8">{content}</p>
          {moreInfo && href && (
            <div className="flex justify-end mt-auto mr-4">
              <a
                href={href}
                className="text-black font-body font-semibold border border-[#C7D1E9] rounded-lg text-sm hover:text-[#FC623D] hover:border-black transition-colors duration-300 p-2"
              >
                More info →
              </a>
            </div>
          )}
        </div>
      </div>
  );
};


export default ExpandableContent;