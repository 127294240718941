import React from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";
import ExpandableContent from "@client/components/ExpandableContent";

export default (props: templates.MiningEquipemnt) => {
    const handleGetInTouchClick = () => {
        console.log("clicked")
        window.location.href = "/#contact-section";
    };
    return (
        <Scaffold title="Mining Equipement">
            <Navbar/>

            <div>
                <div className="relative w-full h-full">
                    <img
                    src={"/assets/products/Equipemnt.svg"}
                    alt="background"
                    className="w-full h-full object-cover relative z-[-10]"
                    />
                    <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                    <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
                </div>


                <div className="flex flex-col justify-start item-start space-y-2 sm:space-y-4 md:space-y-6 absolute md:w-[78%] lg:w-[70%]  top-[20%] sm:top-[30%] md:top-[30%] lg:[top-50%] left-[8%] md:[left-9%]">
                    <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl">
                    Machinery
                    </h1>
                    <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block">Products / Mining / Equipment</p>

                    <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                    We offer a complete range of mining equipment designed to maximize efficiency and productivity, including crushers, high-performance grinding mills, and mobile units like track-mounted jaw and cone crushers. Built for strength and reliability, our equipment ensures top performance even in tough conditions, supporting peak efficiency throughout the mining cycle.
                    </p>

                </div>
            </div>

            <div className="mx-8 sm:mx-10 md:mx-16 lg:px-32 my-10 sm:my-20">

                <div className="flex flex-col">
                    <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">Key Features</h1>
                    <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                        <li className="list-disc">Durability: Built to withstand harsh mining environments and extended use, ensuring long-term reliability.</li>
                        <li className="list-disc">High Performance: Engineered for efficient material processing, from crushing to grinding, enhancing overall productivity.</li>
                        <li className="list-disc">Versatility: Suitable for a variety of mining applications, including primary, secondary, and tertiary processing.</li>
                        <li className="list-disc">Mobility: Includes mobile units such as track-mounted crushers, offering flexibility and quick setup on different job sites.</li>
                        <li className="list-disc">Consistent Operation: Designed to provide reliable and stable performance, reducing downtime and maintenance needs.</li>
                        <li className="list-disc">Enhanced Efficiency: Optimized for effective material handling and processing, supporting improved mining operations and throughput.</li>
                    </ul>
                </div>

            <div className="flex flex-col justify-center items-center font-body md:px-20 lg:px-24 xl:px-64">

                <ExpandableContent
                    title="Crushers"
                    content="HIPEROY’s crushers are designed for superior performance and durability in mining applications. 
                    Our range includes robust jaw crushers and efficient cone crushers, engineered to handle various ore types and sizes. 
                    These crushers deliver reliable, high-capacity crushing, 
                    ensuring optimal processing and productivity in your mining operations."
                    moreInfo={true}
                    href="crushers/"
                />
                <ExpandableContent
                    title="Mobile Series"
                    content="HIPEROY’s Mobile Series equipment provides flexible and efficient solutions for mining operations. 
                    Featuring track-mounted jaw crushers, cone crushers, vertical shaft impactors, screens, and scalpers,
                     these mobile units offer versatile and high-performance processing with easy mobility. 
                    Ideal for dynamic mining environments, they enhance productivity and adaptability on-site."
                    moreInfo={true}
                    href="mobile-series/"
                />
                <ExpandableContent
                    title="Semi Mobile Series"
                    content="HIPEROY’s Semi-Mobile Series combines mobility with stability, offering high-performance solutions for mining. Featuring equipment like Profast jaw crushers, cone crushers, and vertical shaft impactors, this series provides efficient, reliable processing with easy setup and adaptability to dynamic mining environments."
                    moreInfo={true}
                    href="semi-mobile-series/"
                />
            </div>

            </div>


            <ContactInfo/>
            <Footer/>
        </Scaffold>
    )
}