import React, { useRef } from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ExpandableContent from "@client/components/ExpandableContent";
import ContactInfo from "../components/ContactInfo";
import Carousel from "@client/components/Carousel";

export default (props: templates.CementMillLiners) => {
  const scrollContainerRef = useRef(null);

  const millLinerCementImages = [
    "/assets/products/MillLinerCement.svg",
    "/assets/products/MillLinerCement1.svg",
    "/assets/products/MillLinerCement2.svg",
  ];

  const millLinerMiningImages = [
    "/assets/products/MillLinerMining.svg",
    "/assets/products/MillLinerMining1.svg",
    "/assets/products/MillLinerMining2.svg",
    "/assets/products/MillLinerMining3.svg",
  ];
  return (
    <Scaffold title="Mill Liner">
      <Navbar scrollContainerRef={scrollContainerRef} />

      <div
        ref={scrollContainerRef}
        className="h-screen overflow-y-scroll sm:snap-y sm:snap-mandatory pt-0 sm:pt-20 scroll-smooth"
      >
        <section className="snap-start">
          <div className="relative">
            <div className="hidden sm:block">
              <div className="relative w-full  h-screen">
                <img
                  src={"/assets/products/CMillLiners.svg"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              <div className="flex flex-col justify-start item-start absolute top-[16%] sm:top-[40%] left-[4%] sm:left-[10%] w-[80%]">
                <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl sm:mb-5">
                  Mill Liners
                </h1>
                <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block sm:mb-10">
                  Products / Mill Liners
                </p>

                <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                  Expertly crafted to fit each mill’s needs, our liners ensure
                  peak performance and durability. Tailored to tackle specific
                  challenges, they boost efficiency, reduce wear, and extend
                  service life, making your grinding operations more effective
                  and cost-efficient.
                </p>
              </div>
            </div>

            <div className="block sm:hidden">
              <div className="relative w-full h-full">
                <img
                  src={"/assets/products/MillLinerMob.svg"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              <div className="absolute inset-0 flex flex-col justify-center items-center text-white mx-10">
                <h1 className="font-heading font-normal text-2xl">Mill Liners</h1>
                <p className="font-body text-sm mt-5 text-center">
                  Mill liners are designed for durability and efficiency,
                  providing superior protection and performance in mineral
                  processing mills.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="snap-start">
          <div className="mx-12 sm:mx-10 md:mx-16  lg:px-32 py-10 sm:py-24">
            <div className="flex flex-col">
              <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">
                Key Features
              </h1>
              <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                <li className="list-disc">
                  Metallic liners designed for SAG, AG, ball, and rod mills in
                  mineral grinding processes.
                </li>

                <li className="list-disc">
                  Liner profiles are customized based on client-provided
                  drawings.
                </li>

                <li className="list-disc">
                  Collaborative design process aims to improve performance and
                  efficiency.
                </li>

                <li className="list-disc">
                  First chamber shell liners are lifting types made from a
                  durable alloy that prevents breakage, even when worn down to
                  20 mm thickness.
                </li>

                <li className="list-disc">
                  Second chamber shell liners position smaller media near the
                  outlet for optimal grinding, ensuring efficient performance
                  and reducing power consumption.
                </li>
              </ul>
            </div>

            <div className="flex flex-col justify-center items-center font-body md:px-20 lg:px-24 xl:px-64">
              <ExpandableContent
                title="Mill Liners - Cement"
                content="The first chamber shell liners are lifting type to crush material effectively while minimizing power consumption and preventing breakage, even at 20 mm thickness. The second chamber shell liners will ensure effective grinding and reduce power usage by segregating smaller grinding media near the mill's outlet."
                additionalInfo={
                  <div className="flex flex-col md:flex-row items-center justify-center p-4 px-8">
                    {/* Image Section */}
                    <div className="w-full sm:w-1/2 flex justify-center sm:justify-start">
                      <Carousel images={millLinerCementImages} />
                    </div>
                  </div>
                }
              />

              <ExpandableContent
                title="Mill Liners - Mining"
                content="Metallic liners for all types of SAG mills, AG mills, ball mills, and rod mills used in mineral grinding processes. Liners profile design based on client drawings, and collaboration in design to improve liner performance."
                additionalInfo={
                  <div className="flex flex-col md:flex-row items-center justify-center p-4 px-8">
                    {/* Image Section */}
                    <div className="w-full sm:w-1/2 flex justify-center sm:justify-start">
                      <Carousel images={millLinerMiningImages} />
                    </div>
                  </div>
                }
              />
            </div>

            <ContactInfo />
          </div>
          <Footer />
        </section>
      </div>
    </Scaffold>
  );
};
