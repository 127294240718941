import React from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";
import ExpandableContent from "../components/ExpandableContent";


export default (props: templates.DipTube) => {
    return (
        <Scaffold title="Dip Tube">
            <Navbar/>

            <div>
                <div className="relative w-full h-full">
                    <img
                    src={"/assets/products/DipTube.svg"}
                    alt="background"
                    className="w-full h-full object-cover relative z-[-10]"
                    />
                    <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                    <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
                </div>


                <div className="flex flex-col justify-start item-start space-y-2 sm:space-y-4 md:space-y-6 absolute md:w-[78%] lg:w-[70%]  top-[20%] sm:top-[30%] md:top-[30%] lg:[top-50%] left-[8%] md:[left-9%]">
                    <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl">
                    Dip Tube
                    </h1>
                    <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block">Products / Cement / Dip Tube</p>

                    <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                    HIPEROY offers high-performance dip tubes designed for durability and effective material transfer in cement processing. Built to withstand tough conditions, our dip tubes ensure steady, efficient cement flow and minimal wear. With HIPEROY’s dip tubes, you’ll experience improved productivity and optimized cement manufacturing performance.
                    </p>
                </div>
            </div>

            <div className="mx-8 sm:mx-10 md:mx-16 lg:px-32 my-10 sm:my-20">

                <div className="flex flex-col justify-center items-center">
                    <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading">Key Features</h1>
                    <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg text-justify leading-5 sm:leading-8">
                        <li className="list-disc">Durable Construction: Made from high-strength, wear-resistant materials to withstand harsh processing environments.</li>
                        <li className="list-disc">Efficient Material Transfer: Engineered to ensure smooth and consistent flow of cement, minimizing blockages and downtime.</li>
                        <li className="list-disc">Corrosion Resistance: Designed to resist corrosion and abrasion, extending the lifespan of the equipment.</li>
                        <li className="list-disc">High Temperature Tolerance: Capable of operating effectively at elevated temperatures commonly found in cement processing.</li>
                        <li className="list-disc">Precision Engineering: Manufactured to exact specifications for a perfect fit and reliable performance.</li>
                        <li className="list-disc">Easy Maintenance: Designed for straightforward installation and maintenance, reducing operational interruptions.</li>
                    </ul>
                </div>
            
            </div>


            <ContactInfo/>
            <Footer/>
        </Scaffold>
    )
}