import React from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BodyLinerBanner from "../components/BodyLinerBanner";

import ContactInfo from "../components/ContactInfo";



export default (props: templates.BodyLiner) => {
    return (
        <Scaffold title="Body Liner">
            <Navbar/>
            <BodyLinerBanner/>

            <div className="mx-8 sm:mx-10 md:mx-16 lg:px-32 my-10 sm:my-20">

                <div className="flex flex-col">
                    <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">Key Features</h1>
                    <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                        <li className="list-disc">High-Performance Alloys: Utilizes specially chosen alloys that offer superior resistance to erosion and abrasion.</li>
                        <li className="list-disc">Advanced Design: Engineered to handle both the air flow through the mill and abrasive materials with minimal wear.</li>
                        <li className="list-disc">Enhanced Durability: Extended lifespan due to reduced wear and tear, leading to fewer replacements and lower maintenance costs.</li>
                        <li className="list-disc">Improved Efficiency: Maintains consistent performance, contributing to more efficient milling operations.</li>
                        <li className="list-disc">Reduced Operating Costs: Lower overall costs due to decreased need for frequent maintenance and replacement.</li>
                        <li className="list-disc">Reliable Performance: Designed to withstand harsh operating conditions, ensuring dependable and long-term use.</li>

                    </ul>
                </div>
                <ContactInfo/>
            </div>
            <Footer/>
        </Scaffold>
    )
}