import React from "react";

const ProductBanner = () => {
  return (
    <div className="relative">
      <div className="hidden sm:block">
        <div className="relative w-full h-full h-screen">
          <img
            src={"/assets/products/ProductBg.svg"}
            alt="background"
            className="w-full h-full object-cover relative z-[-100]"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
          <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
        </div>


        <div className="flex flex-col justify-start item-start absolute top-[16%] sm:top-[40%] lg:top-[50%] left-[4%] sm:left-[10%] w-[80%]">
            <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl mb-0 sm:mb-5">
            Products
            </h1>

          <p className="font-body text-sm sm:text-xl text-white">
          At HIPEROY, we provide high-performance products for mining and cement manufacturing, including advanced grinding media, tough dip tubes, and precision-engineered spare parts. Our solutions boost efficiency and reliability, with cutting-edge technology ensuring long-lasting performance in tough conditions.
          </p>

        </div>
      </div>

      <div className="block sm:hidden">
        <div className="relative w-full h-full">
          <img
            src={"/assets/products/ProductBgMob.svg"}
            alt="background"
            className="w-full h-full object-cover relative z-[-100]"
          />
          <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
        </div>

        {/* Text content positioned over the image */}
        <div className="absolute inset-0 flex flex-col justify-center items-center text-white mx-10">
          <h1 className="font-heading font-normal text-2xl">
            Products
          </h1>
          <p className="font-body text-sm mt-5 text-center">
          HIPEROY offers a comprehensive range of mining and cement equipment, including crushers, grinding media, and essential spares, all designed for durability and high performance.
          </p>
        </div>
      </div>



    </div>
  );
};

export default ProductBanner;
